<template>
  <div class="home grey lighten-3" :class="{'mobile': $vuetify.breakpoint.smAndDown}">

    <main-nav :userId="userId" v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav :userId="userId" v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>


    <div class="fill-height" :class="{'d-flex align-center justify-center': !isLoaded}">
      <!-- loading -->
      <!-- <template v-if="!isLoaded">
        <v-card class="col-xs-10 col-md-3">
          <v-card-title>
            Loading Youtorials
          </v-card-title>
          <v-card-text>
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </template> -->

      <!-- videos -->
      <video-cards :videos="videos" :loading="loading"></video-cards>



    </div>

  </div>
</template>

<script>
const mobileNav =() => import("@/components/shared/mobile-nav.vue");
const mainNav = () => import("@/components/shared/nav.vue");
const videoCards = () => import("@/components/shared/video-cards.vue");
import { db } from '@/firebase';
import { mapGetters } from 'vuex'
export default {
  name: "videos",
  metaInfo: {
    title: 'Youtorial: Home - Youtube Tutorials Made Awesome'
  },
  components:{
    mainNav,videoCards,mobileNav,
  },
  computed:{
    ...mapGetters({
      user:'GET_USER_ID',
    }),
  },
  data(){
    return{
      videos: [],
      isLoaded: false,
      loading:true,
      userId:0,
    }
  },
  methods:{
    getVideos(){
      let vThis = this;
      vThis.$binding("videos", db.collection('videos').where('published','==', true))
      .then((res) => {
        vThis.isLoaded = true;
        vThis.loading = false;
      }).catch(err => {
        console.error(err)
      })
    },
  },
  mounted(){
    this.getVideos();
  }
  // firestore() {
  //   return {
  //     videos: db.collection('videos').where('userId','==', this.user)
  //   }
  // },
};
</script>


<style lang="scss">
.home{
  position: relative;
  height: 100vh;
  padding-top: 70px;
  &.mobile{
    padding-top:0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
    }
  }
}
</style>
